import React from 'react';
import Hero from '../Components/Hero/Hero';
import LocationInMap from '../Components/Map/LocationInMap';
import Contact from '../Components/Contact/Contact';
import Department from '../Components/Department/Department';
// import Appointment from '../Components/Appointment/Appointment';
import About from '../Components/About/About';
import Iconbox from '../Components/Iconbox/Iconbox';
import PostWrapper from '../Components/Post/PostWrapper';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import BrandSlider from '../Components/Slider/BrandSlider';
import Newsletter from '../Components/Newsletter/Newsletter';
import Accordion from '../Components/Accordion/Accordion';
// import PriceSlider from '../Components/Slider/PriceSlider';
import Funfact from '../Components/Funfact/Funfact';
import BeforeAfter from '../Components/BeforeAfter/BeforeAfter';
import MasonryGallery from '../Components/Gallery/Gallery';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/boy.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/girl2.png',
    },
    {
      img: 'images/boy.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/girl2.png',
    },
  ],
  title: ['Surgery', 'Paediatrics', 'Orthopedics', 'Urology', 'Dental'],
};

const iconboxData = [
  {
    bg: 'purple',
    icon: 'icons/icon1.svg',
    title: 'Qualified Doctors',
    subTitle:
      'Top doctors ready to serve your needs with quality care whenever you need it most.',
  },
  {
    bg: 'green',
    icon: 'icons/icon2.svg',
    title: '24 Hours Service',
    subTitle:
      'We are available around the clock to address your needs. and get the care you deserve around the clock.',
  },
  {
    bg: 'red',
    icon: 'icons/icon3.svg',
    title: 'Need Emergency',
    subTitle:
      '24/7 emergency services because time matters and get ambulance service completely free.',
  },
];

const aboutData = {
  title:
    'A multi-specialty Hospital equipped with all state-of-art facilities',
  subTitle:
    'together with high-tech technology to match world-class standards and support services we pledge to make your stay at the hospital peaceful and comfortable.<br /><br /> While designing the hospital, the special feature kept in mind was to create a de-stressing atmosphere and ambience for the patients and the doctors. The large and well-lit corridors with abundant space for relatives in each department ensures that the whole facility not only looks relaxed but also works very efficiently. <br /><br />We will put the patient first and foremost. That is our commitment and we will do everything possible within our reach to fulfill this commitment.',

  avater: {
    img: 'images/rajkumar.png',
    name: 'Dr. Rajkumar',
    designation: 'Managing Director',
  },
  timeTable: [
    {
      day: 'Monday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Tuesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Wednesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Thursday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Friday',
      startTime: '9:00',
      endTime: '5:00',
    },
    {
      day: 'Saturday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Sunday',
      startTime: '9:00',
      endTime: '3:00',
    },
  ],
  contact: '(+01) - 234 567 890',
};

const specialistData = [
  {
    img: 'images/md.jpg',
    name: 'Dr. Rajkumar',
    designation: 'Managing Director',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/nurse.jpg',
    name: 'Dr. Sindhuja',
    designation: 'Gynecologist ',
    subdesignation:' MBBS, DGO',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/doctor.jpg',
    name: 'Dr. Gyaninder',
    designation: ' Physiotheripist',
    subdesignation:' MPT',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/doctor.jpg',
    name: 'Dr. L.N. Singh',
    designation: 'General Physician',
    subdesignation:'MBBS MD',
    authorLink: '/doctor-profile',
  },

 
];

const beforeAfterData = {
  bgImg: '/images/before-after-bg.jpg',
  beforeImg: '/images/after.jpg',
  afterImg: 'images/before.jpg',
};

const testimonialData = [
  {
    img: 'images/rajkumar2.png',
    name: 'Sunita Sharma',
    designation: 'Delhi',
    subTitle:
      " I recently visited Tejmuni New Life Hospital for a consultation. I had a positive experience at Tejmuni New Life Hospital and would recommend it to others.",
  },
  {
    img: 'images/rajkumar3.png',
    name: ' Raj Kapoor',
    designation: 'Delhi',
    subTitle:
      " My experience at Tejmuni New Life Hospital was a mixed bag. and the appointment process was fairly smooth. I would consider going back to Tejmuni New Life Hospital.",
  },
  {
    img: 'images/rajkumar4.png',
    name: 'Amit Gupta',
    designation: 'Delhi',
    subTitle:
      " I am very grateful for the care I received at Tejmuni New Life Hospital.They provided excellent medical care and made sure we were comfortable and informed throughout the process.",
  },
  {
    img: 'images/avatar11.png',
    name: 'Priya Singh',
    designation: 'Delhi',
    subTitle:
      "As someone who grew up in Delhi, I've been to my fair share of hospitals. If you're looking for a good dental care in Delhi, Tejmuni New Life Hospital is definitely worth considering",
  },

];

// const priceData = [
//   {
//     title: 'Blood Test',
//     price: '39',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '0',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Hemoglobin Test',
//     price: '89',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Homocysteine Test',
//     price: '150',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '1',
//       },
//     ],
//   },
//   {
//     title: 'Blood Test',
//     price: '39',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '0',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Hemoglobin Test',
//     price: '89',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Homocysteine Test',
//     price: '150',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '1',
//       },
//     ],
//   },
// ];

const faqData = {
  title: 'Just Answer the Questions',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'What services does Tejmuni New Life Hospital offer?',
      content: `Tejmuni New Life Hospital offers a wide range of services, including routine OPD, emergency services, diagnostic services, pharmacy services, day care services, medicine department, gynecology department, surgery department, pediatrics and neonatal department, joint replacement services, urology services, anesthesia services, dental services, physiotherapy services, laboratory services, ICU and NICU facilities, ultrasound services, X-ray services, and canteen and food services`,
    },
    {
      title: 'Does Tejmuni New Life Hospital offer emergency services?',
      content: `Yes, Tejmuni New Life Hospital offers emergency services. The provided information states that they provide 24x7 emergency services in the casualty department.`,
    },
    {
      title: 'What are the specialties offered at Tejmuni New Life Hospital?',
      content: `The specialties offered at Tejmuni New Life Hospital include Medicine, Gynecology, Surgery, Pediatrics, Orthopedics, Urology, Anesthesia, Dental, Physiotherapy, and Pathology`,
    },
    {
      title: 'How can I schedule an appointment at Tejmuni New Life Hospital?',
      content: `To schedule an appointment at Tejmuni New Life Hospital, you can contact directly at the given contact numbers (8130982231, 011-46078369) or email at tejmuninewlifehospital@gmail.com. We will assist you in scheduling your appointment.`,
    },
    {
      title: 'Does Tejmuni New Life Hospital accept my insurance?',
      content: `It is advisable to contact the hospital directly at the given contact numbers (8130982231, 011-46078369) or email them at tejmuninewlifehospital@gmail.com to inquire about the specific insurance companies or TPAs that they work with and accept. They should be able to provide you with the necessary information regarding insurance coverage.`,
    },
  ],
};

const newsletterData = {
  bgImg: 'images/news-letter-bg.png',
  contact: '(+01) - 234 567 890',
};

const postData = [
  {
    img: 'images/blog1.jpg',
    title: 'Working in emergency medicine',
    date: 'Aug 23, 2020',
    author: 'Albert Brian',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog2.jpg',
    title: 'Individual treatment & assistance',
    date: 'Aug 22, 2020',
    author: 'William Juarez',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
];

const brandData = [
  {
    bg: '',
    img: 'images/brand1.png',
  },
  {
    bg: '',
    img: 'images/brand2.png',
  },
  {
    bg: '',
    img: 'images/brand3.png',
  },
  {
    bg: '',
    img: 'images/brand11.png',
  },

  {
    bg: '',
    img: 'images/client6.png',
  },
  {
    bg: '',
    img: 'images/client7.png',
  },
  {
    bg: '',
    img: 'images/client8.png',
  },
  {
    bg: '',
    img: 'images/client9.png',
  },
  {
    bg: '',
    img: 'images/client10.png',
  },
  {
    bg: '',
    img: 'images/client11.png',
  },
  {
    bg: '',
    img: 'images/client12.png',
  },
  {
    bg: '',
    img: 'images/client13.png',
  },
  {
    bg: '',
    img: 'images/client14.png',
  },
  {
    bg: '',
    img: 'images/client15.png',
  },
];

const mapLocationURL =
"https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10397.481623059291!2d77.08071402772342!3d28.590541270093933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1715161490683!5m2!1sen!2sin"
const Home = () => {
  return (
    <>
      <Hero data={heroData} />
      <Iconbox data={iconboxData} />
      <About data={aboutData} />
      <Department />
      {/* <Appointment /> */}
      <SpecialistsSlider data={specialistData} />
      <hr />
      <MasonryGallery />
      {/* <BeforeAfter data={beforeAfterData} /> */}
      <TestimonialSlider data={testimonialData} />
      <Funfact />
      {/* <PriceSlider data={priceData} /> */}
      <Accordion data={faqData} />
      {/* <Newsletter data={newsletterData} /> */}
      {/* <PostWrapper data={postData} /> */}
      <BrandSlider data={brandData} />
      <Contact />
      <LocationInMap data={mapLocationURL} />
    </>
  );
};

export default Home;
